import React, { Component } from "react";

import networkIssueImage from "assets/images/networkissue.svg";
import oops from "assets/images/Oops.svg";
import four_o_four from "assets/images/404_svg.svg";

class InternetConnectivityChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: navigator.onLine,
    };
  }

  componentDidMount() {
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  };

  handleOffline = () => {
    this.setState({ isOnline: false });
  };

  render() {
    const { isOnline } = this.state;

    return (
      <div className="bg-transparent w-full">
        {isOnline ? (
          this.props.children // Render the children when online
        ) : (
          <div className="w-[100%] h-[100vh] flex justify-center items-center flex-col">
            <img alt="" src={oops} />
            <img alt="" src={four_o_four} className="mb-44" />
            <img alt="" src={networkIssueImage} className="mb-40" />
          </div>
        )}
      </div>
    );
  }
}

export default InternetConnectivityChecker;
