import { Main } from "./main";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { Suspense } from "react";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense>
            <Main />
          </Suspense>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
