import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import HomePage from "./pages/HomePage";
import ChatPage from "./pages/ChatPage";
import Sidebar from "./components/Sidebar";
import GetStartedPage from "pages/GetStartedPage";
import { useLocation } from "react-router-dom";
import InternetConnectivityChecker from "components/InternetChecker";
import { AuthProvider } from "contexts/Auth";

import {
  AuthenticatedOnlyRoute,
  UnAuthenticatedOnlyRoute,
} from "components/Routes";

import { setThemeColors } from "helper/GetStartedPageHelper";
import { useDispatch } from "react-redux";
import { fetchUserThemeData } from "api/profiles";
import { setUserOrg } from "./redux/slice/user";

export function Main() {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Get current location
  const dispatch = useDispatch();

  const [animatedLogo, setAnimatedLogo] = useState(null);
  const [animatedbackground, setAnimatedBackground] = useState(null);
  const [themeData, setThemeData] = useState(null);

  // Determine if the sidebar should be displayed
  const showSidebar = location.pathname !== "/";

  useEffect(() => {
    fetchUserThemeData().then((userThemeData) => {
      const currentTime = Date.now();
      const updatedThemeData = {
        ...userThemeData,
        theme_timer: currentTime,
      };

      setThemeColors(updatedThemeData);
      setThemeData(updatedThemeData);
      dispatch(setUserOrg(updatedThemeData));
      setAnimatedLogo(updatedThemeData.org_logo_animation);
      setAnimatedBackground(updatedThemeData.org_get_started_bg_animation);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <></>
  ) : (
    <AuthProvider>
      <div className=" h-[100vh] w-full flex flex-row">
        {showSidebar && <Sidebar open={open} setOpen={setOpen} />}
        <div className="flex-grow  h-full">
          <Routes>
            <Route
              path="/"
              element={
                <InternetConnectivityChecker>
                  <UnAuthenticatedOnlyRoute>
                    <GetStartedPage
                      animatedLogo={animatedLogo}
                      animatedbackground={animatedbackground}
                      themeData={themeData}
                    />
                  </UnAuthenticatedOnlyRoute>
                </InternetConnectivityChecker>
              }
            />
            <Route
              path="/homepage"
              element={
                <InternetConnectivityChecker>
                  <AuthenticatedOnlyRoute>
                    <HomePage />
                  </AuthenticatedOnlyRoute>
                </InternetConnectivityChecker>
              }
            />
            <Route
              path="/chatpage/:conversationId?"
              element={
                <InternetConnectivityChecker>
                  <AuthenticatedOnlyRoute>
                    <ChatPage />
                  </AuthenticatedOnlyRoute>
                </InternetConnectivityChecker>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
}
