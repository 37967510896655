import { sendLogToServer } from "./logging";
import axios from "axios";
import apiEndpoints from "./apiConfig";
import { handleApiError } from "./apiConfig";

export const createConversationNewChat = async (conversation_name, user_id) => {
  sendLogToServer("createConversationNewChat");
  try {
    const data = await axios.post(
      apiEndpoints.CONVERSATION.ADD_CONVERSATION,
      { conversation_name: conversation_name, user_id: user_id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return [data.data];
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error createConversationNewChat: ${error}`);
    handleApiError(error);
  }
};

export const getAllConversation = async () => {
  sendLogToServer("getAllConversation");
  try {
    const data = await axios.get(apiEndpoints.CONVERSATION.GET_CONVERSATION, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return data.data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error getAllConversation: ${error}`);
    handleApiError(error);
  }
};

export const deleteConversation = async (conversation_id) => {
  sendLogToServer("deleteConversation");
  try {
    const data = await axios.post(
      apiEndpoints.CONVERSATION.DELETE_CONVERSATION,
      { conversation_id: conversation_id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return [data.data];
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error deleteConversation: ${error}`);
    handleApiError(error);
  }
};

export const deleteAllConversations = async () => {
  sendLogToServer("deleteAllConversations");
  try {
    const data = await axios.post(
      apiEndpoints.CONVERSATION.DELETE_ALL_CONVERSATIONS,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return [data.data];
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error deleteAllConversations: ${error}`);
    handleApiError(error);
  }
};
