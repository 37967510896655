import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    data: null,
    org: null,
    isLoading: false,
    isError: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    setUserOrg: (state, action) => {
      state.org = action.payload;
    },
  },
});

export const { setUserData, setUserOrg } = user.actions;
export default user.reducer;
