import React, { useEffect, useState } from "react";
import hamburger from "assets/images/hamburger.svg";
import { FiHome } from "react-icons/fi";
import { MdOutlineChat } from "react-icons/md";
import contentS from "assets/images/content.svg";
import bin from "assets/images/bin.svg";
import faq from "assets/images/faq.svg";
import logout from "assets/images/logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import SVGIcon from "./SVGIcon";
import { LogoutButton } from "api/auth";
import { IoAdd } from "react-icons/io5";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export default function Sidebar({ open, setOpen }) {
  const userData = useSelector((state) => state.userData);

  const navigate = useNavigate();
  const location = useLocation();
  const Menus = [
    {
      title: "Home",
      src: (
        <FiHome
          size={22}
          className={` ml-1 ${
            location.pathname === "/homepage"
              ? "text-white"
              : "text-black dark:text-white"
          }`}
        />
      ),
      route: "/homepage",
    },
    {
      title: "Chats",
      src: (
        <MdOutlineChat
          size={22}
          className={` ml-1 ${
            location.pathname === "/chatpage"
              ? "text-white"
              : "text-black dark:text-white"
          }`}
        />
      ),
      route: "/chatpage",
    },
    // { title: "Content suggestions", src: null, src2: contentS, route: "/co" },
  ];

  const Menus2 = [
    // {
    //   id: uuidv4(),
    //   title: "Clear conversations",
    //   src: bin,
    //   route: "/homepage",
    //   avatar: null,
    // },
    // {
    //   id: "Profile12345",
    //   title: "Profile",
    //   src: chats,
    //   route: "/homepage",
    //   avatar: userData.data ? userData.data.avatar_url : null,
    // },
    // {
    //   id: uuidv4(),
    //   title: "Updates & FAQ",
    //   src: faq,
    //   route: "/homepage",
    //   avatar: null,
    // },
    // { title: "Log out", src: logout, route: "/homepage" },
  ];
  useEffect(() => {
    if (location.pathname === "/chatpage") {
      setOpen(false);
    }
  }, [location.pathname]);
  return (
    <div
      className={` ${
        open ? "w-60 2xl:w-72 " : "w-20"
      } bg-white border-r-[1px] dark:border-[#333333] h-screen p-4 justify-center  pt-8 relative duration-300 flex flex-col dark:bg-background-dark`}
    >
      <div className="flex  items-center justify-between mb-6">
        <div
          className="flex flex-row items-center gap-4"
          onClick={() => setOpen(true)}
        >
          <img
            alt="logo"
            src={userData.org.org_logo}
            className={`cursor-pointer h-[35px] duration-500 ${
              open && "rotate-[360deg]"
            }`}
            onClick={() => setOpen(true)}
          />
          <img
            className={`text-primary-light origin-left  w-[100px] duration-200 ${
              !open && "hidden"
            }`}
            alt="navbar-logo2"
            src={userData.org.org_logo2}
          />
          {/* <h1
            className={`text-primary-light origin-left  text-[24px] duration-200 ${
              !open && "scale-0"
            }`}
          >
            Care.AI
          </h1> */}
        </div>

        <div
          className={`cursor-pointer ${!open && "hidden"} `}
          // className={`cursor-pointer `}
          onClick={() => setOpen(!open)}
        >
          <SVGIcon
            id={`MenuOpenCloseButton`}
            src={hamburger}
            fill="none"
            stroke="black"
            svg_path_class="dark:stroke-white"
          />
        </div>
      </div>
      <button
        onClick={() => {
          navigate("/chatpage");
        }}
        className="w-full h-max rounded-lg bg-black dark:bg-foreground-dark py-2  gap-6 flex flex-row items-center justify-center"
      >
        <IoAdd className="text-white dark:text-white" size={20} />
        <h1
          className={`text-white ${
            !open && "hidden"
          } whitespace-nowrap dark:text-white overflow-hidden text-overflow-ellipsis text-sm 2xl:text-base`}
        >
          New Chat
        </h1>
      </button>
      <div className=" flex-grow flex flex-col justify-between">
        <ul className="">
          {Menus.map((Menu, index) => (
            <li
              onClick={() => {
                navigate(Menu.route);
                setOpen(false);
              }}
              key={index}
              className={`flex rounded-md p-2 ${
                location.pathname === Menu.route ? "bg-primary-light" : ""
              }  cursor-pointer hover:bg-light-white text-black text-base items-center gap-x-4 h-[40px]
              ${Menu.gap ? "mt-9" : "mt-6"} ${
                index === 0 && "bg-light-white"
              } `}
            >
              {Menu.src ? (
                Menu.src
              ) : (
                <SVGIcon
                  id={`${Menu.title}${index}Menu`}
                  src={Menu.src2}
                  fill={location.pathname === Menu.route ? "white" : "black"}
                  svg_path_class="dark:fill-white"
                />
              )}

              <span
                className={`${!open && "hidden"} ${
                  location.pathname === Menu.route
                    ? "text-white"
                    : "text-black dark:text-white"
                }   origin-left text-sm 2xl:text-base duration-200 whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
              >
                {Menu.title}
              </span>
            </li>
          ))}
        </ul>
        <ul className="">
          <div className="w-full border-b-[1px] border-r-emerald-900 dark:border-[#333333]"></div>

          {Menus2.map((Menu2, index) => (
            <li
              onClick={() => {
                navigate(Menu2.route);
                setOpen(false);
              }}
              key={index}
              className={`flex rounded-md p-2  cursor-pointer hover:bg-light-white text-black dark:text-white text-base items-center gap-x-4 
              ${Menu2.gap ? "mt-9" : "mt-6"} ${
                index === 0 && "bg-light-white"
              } `}
            >
              {Menu2.avatar ? (
                <img alt="avatar" src={Menu2.avatar} className="w-7" />
              ) : (
                <SVGIcon
                  id={`${Menu2.id}`}
                  src={Menu2.src}
                  fill="black"
                  svg_path_class="dark:fill-white"
                />
              )}
              <span
                className={`${
                  !open && "hidden"
                } text-black dark:text-white transition-transform hover:translate-x-2  origin-left duration-200 whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
              >
                {Menu2.title}
              </span>
            </li>
          ))}
          <LogoutButton>
            <li
              onClick={() => {
                navigate("/");
                setOpen(false);
              }}
              key={22}
              className={`flex rounded-md p-2  cursor-pointer hover:bg-light-white text-black dark:text-white text-base items-center gap-x-4 
              mt-6 bg-light-white
            `}
            >
              {/* <img className="text-red-400" alt="nav" src={Menu2.src} /> */}
              <SVGIcon
                id={`logoutMenu22`}
                src={logout}
                fill="black"
                svg_path_class="dark:fill-white"
              />
              <span
                className={`${
                  !open && "hidden"
                } text-black text-sm 2xl:text-base dark:text-white transition-transform hover:translate-x-2  origin-left duration-200 whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
              >
                Log out
              </span>
            </li>
          </LogoutButton>
        </ul>
      </div>
    </div>
  );
}
