import React from "react";
import bell from "assets/images/bell.svg";
import SVGIcon from "./SVGIcon";
import ThemeToggle from "./ThemeToggle";
import { VscBell } from "react-icons/vsc";
import { useSelector } from "react-redux";

export default function Navbar(props) {
  const userData = useSelector((state) => state.userData);
  return (
    <div className="w-full h-full  flex flex-row items-center justify-center">
      <div className="w-[80%] max-w-[1200px] flex flex-row justify-between items-center">
        {/* <h1 className="text-xl font-semibold text-secondry-light ">
          BlueDot Health Insurance
        </h1> */}
        <div className=" h-8 flex flex-row">
          {/* <img alt="navbar-logo" src={userData.org.org_logo} />
          <img alt="navbar-logo2" src={userData.org.org_logo2} /> */}
        </div>
        <div className="flex flex-row items-center gap-8">
          {props.component}
          <ThemeToggle />
          {/* <SVGIcon id={`bellIcon`} src={bell} fill="black" /> */}
          {/* <VscBell size={24} className="dark:text-white" /> */}
        </div>
      </div>
    </div>
  );
}
