export const groupConversationsByDate = (conversations) => {
  const groups = [
    { id: 0, data: [], name: "Today" },
    { id: 1, data: [], name: "Yesterday" },
    { id: 2, data: [], name: "Last 7 Days" },
    { id: 3, data: [], name: "Last 30 Days" },
  ];

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const uniqueObjects = conversations.filter(
    (obj, index, self) =>
      index ===
      self.findIndex((t) => t.conversation_name === obj.conversation_name)
  );

  conversations.forEach((convo) => {
    const convoDate = new Date(convo.created_at); // assuming convo.date is the date of the conversation
    const diffTime = now - convoDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      groups[0].data.push(convo);
    } else if (diffDays === 1) {
      groups[1].data.push(convo);
    } else if (diffDays <= 7) {
      groups[2].data.push(convo);
    } else if (diffDays <= 30) {
      groups[3].data.push(convo);
    }
  });

  return groups;
};
// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: ChatPageHelper2.js
//  Author: Everlign Development Team
// ----------------------------
