// src/apiConfig.js
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_APP_SERVICES_RUNPOD_ENDPOINT;

const apiEndpoints = {
  AUTH: {
    LOGIN: `${API_BASE_URL}/auth/token`,
    SESSION_CHECK: `${API_BASE_URL}/auth/session-check`,
  },
  PROFILE: {
    GET_PROFILE: `${API_BASE_URL}/profile/get-user-profile`,
    GET_THEME_DATA: `${API_BASE_URL}/profile/get-user-theme`,
  },
  MODEL: {
    GET_ALL_MODELS: `${API_BASE_URL}/get-models`,
  },
  MESSAGE: {
    ADD_MESSAGE: `${API_BASE_URL}/message/add-message`,
    GET_MESSAGES: `${API_BASE_URL}/message/get-messages`,
    UPDATE_MESSAGE_LIKE: `${API_BASE_URL}/message/update-like`,
  },
  CONVERSATION: {
    ADD_CONVERSATION: `${API_BASE_URL}/conversation/add-conversation`,
    GET_CONVERSATION: `${API_BASE_URL}/conversation/get-user-conversation`,
    DELETE_CONVERSATION: `${API_BASE_URL}/conversation/delete-conversation`,
    DELETE_ALL_CONVERSATIONS: `${API_BASE_URL}/conversation/delete-all-conversations`,
  },
  STORAGE: {
    GET_FILE: `${API_BASE_URL}/storage/get-pdf-page-url`,
    GET_ORGS: `${API_BASE_URL}/storage/list-orgs`,
  },
  PRODUCTS: {
    GET_ALL: `${API_BASE_URL}/products`,
    GET_BY_ID: (id) => `${API_BASE_URL}/products/${id}`,
    CREATE: `${API_BASE_URL}/products/create`,
    UPDATE: (id) => `${API_BASE_URL}/products/update/${id}`,
    DELETE: (id) => `${API_BASE_URL}/products/delete/${id}`,
  },
  // Add more endpoints as needed
};

export default apiEndpoints;

export const handleApiError = (error) => {
  if (axios.isAxiosError(error)) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      console.error("Server responded with a status:", error.response.status);
      console.error("Response data:", error.response.data);
      console.error("Response headers:", error.response.headers);

      switch (error.response.status) {
        case 400:
          console.error("Bad Request");
          alert("Bad Request: Please check your input.");
          break;
        case 401:
          console.error("Unauthorized");
          alert("Unauthorized: Please log in.");
          // You can also redirect to the login page here
          window.location.href = "/";

          break;
        case 403:
          console.error("Forbidden");
          alert("Forbidden: You don't have permission to perform this action.");
          break;
        case 404:
          console.error("Not Found");
          alert("Not Found: The requested resource could not be found.");
          break;
        case 500:
          console.error("Internal Server Error");
          alert("Internal Server Error: Please try again later.");
          break;
        default:
          console.error(`Unexpected error: ${error.response.status}`);
          alert(
            `Unexpected error: ${error.response.status}. Please try again later.`
          );
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      alert("No response from server. Please check your network connection.");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error in request setup:", error.message);
      alert("Error in request setup: " + error.message);
    }
  } else {
    // Non-Axios error (e.g., a JavaScript error in your code)
    console.error("Error:", error.message);
    alert("An error occurred: " + error.message);
  }
};
