import React from "react";
import Navbar from "components/Navbar";
import SVGIcon from "components/SVGIcon";
import send from "assets/images/send.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HomePage() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);

  return (
    <div className=" w-[100%] h-[100vh] dark:bg-background-dark">
      <div className="py-3 h-[10%] 2xl:h-[6%] box-border  w-full border-b-2 border-gray-100 dark:border-divider-dark  items-center justify-center flex">
        <Navbar />
      </div>
      <div className="h-[30%] 2xl:h-[17%] box-border justify-center w-full  flex">
        <div className="w-[80%] max-w-[1200px] pt-16  flex flex-col justify-start">
          <h1 className="homepage-header-text text-4xl 2xl:text-5xl">
            Hello, {userData.data ? userData.data.full_name : ""}
          </h1>
          <h1 className="text-3xl 2xl:text-4xl dark:text-white">
            How can I help you today?
          </h1>
        </div>
      </div>
      <div className="2xl:h-[65%] h-[45%] gap-4 flex box-border items-center justify-center flex-row">
        <div className="grid max-w-[700px] 2xl:max-w-[900px] grid-cols-3 gap-4 justify-center">
          {userData.org.colors.questions.map((question, index) => (
            <div
              onClick={() => {
                navigate(
                  "/chatpage",
                  {
                    state: {
                      question: question.q,
                    },
                  } // Add your data here
                );
              }}
              key={index}
              className="border-gray-200 cursor-pointer hover:bg-gray-50 dark:border-divider-dark border-2 dark:bg-foreground-dark rounded-md p-3"
            >
              <h1 className="text-xs dark:text-text-dark ">{question.q}</h1>
            </div>
          ))}
        </div>
      </div>
      <div className="2xl:h-[12%] h-[15%] items-center box-border  flex flex-col">
        <div className="w-[80%] max-w-[1200px] bg-[#F7F9FB] dark:bg-foreground-dark h-[45%] rounded-[14px] justify-between gap-3  px-5 flex flex-row ">
          <div className="bg-[#F7F9FB] dark:bg-foreground-dark  flex items-center gap-4 justify-center">
            {/* <SVGIcon
              id={`MicIcon`}
              src={mic}
              fill="black"
              className="cursor-pointer"
              svg_path_class="dark:fill-white"
            />
            <SVGIcon
              id={`ImageUploadIcon`}
              src={imageIcon}
              fill="black"
              className="cursor-pointer"
              svg_path_class="dark:fill-white"
            /> */}
          </div>
          <input
            placeholder="Start typing prompt here"
            className="flex-grow text-xs 2xl:text-sm bg-[#F7F9FB] dark:bg-foreground-dark  px-5 outline-none cursor-pointer"
            onClick={() => {
              navigate("/chatpage");
            }}
          ></input>
          <div className="bg-[#F7F9FB] dark:bg-foreground-dark  flex items-center justify-center">
            <SVGIcon
              id={`SendIcon`}
              src={send}
              fill="#9FA1A2"
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: HomePage.js
//  Author: Everlign Development Team
// ----------------------------
