import React, { useState, useEffect } from "react";
import { marked } from "marked";
import "./style.css";
import DOMPurify from "dompurify";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CiLink } from "react-icons/ci";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import { Chart as defaults } from "chart.js/auto";

defaults.maintainAspectRatio = false;
defaults.responsive = true;

// defaults.plugins.title.display = true;
// defaults.plugins.title.align = "start";
// defaults.plugins.title.font.size = 20;
// defaults.plugins.title.color = "black";

const ChartComponent = ({ labels, data, title, chartType }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Count",
        data: data,
        backgroundColor: [
          "rgba(43, 63, 229, 0.8)",
          "rgba(250, 192, 19, 0.8)",
          "rgba(253, 135, 135, 0.8)",
        ],
        borderColor: [
          "rgba(43, 63, 229, 0.8)",
          "rgba(250, 192, 19, 0.8)",
          "rgba(253, 135, 135, 0.8)",
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      title: {
        text: "Hi",
      },
    },
    rotation: -90,
    circumference: 180,
  };

  const renderChart = () => {
    switch (chartType) {
      case "line":
        return <Line data={chartData} options={chartOptions} />;
      case "bar":
        return <Bar data={chartData} options={chartOptions} />;
      case "pie":
        return <Doughnut data={chartData} options={chartOptions} />;
      default:
        return null;
    }
  };

  return renderChart();
};

function isValidURL(str) {
  const pattern = new RegExp("^(https:|http:|www\\.)\\S*", "i"); // fragment locator
  return !!pattern.test(str);
}

function sourceDocumentsData(source, setFilePreview, setShowFilePreview) {
  if (source.source_documents === undefined) {
    return <></>;
  } else {
    if (source?.source_documents.length === 0) {
      return <></>;
    } else {
      return (
        <div className="cursor-default">
          <h3 className="font-bold text-[18px] mb-4">Sources:</h3>
          {source.source_documents
            ?.map((d, i) => (
              <div
                key={i}
                onClick={() => {
                  if (isValidURL(d["source"])) {
                    window.open(d["source"], "_blank");
                  } else {
                    setFilePreview(d);
                    setShowFilePreview(true);
                  }
                }}
                className="bg-white dark:bg-[#3D3D3D] dark:border-[#3D3D3D] w-fit cursor-pointer border-[1px] border-[#D2D2D2] flex flex-row gap-3 items-center px-4 py-2 my-2 rounded-lg"
              >
                {isValidURL(d["source"]) ? (
                  <>
                    <CiLink color="blue" size={18} />

                    <h3 className="w-56 overflow-hidden text-ellipsis whitespace-nowrap">
                      {d["source"]}
                    </h3>
                  </>
                ) : (
                  <>
                    <AiOutlineFilePdf color="red" size={18} />

                    <h3>
                      {d["source"]} {d["page_number"]}
                    </h3>
                  </>
                )}
              </div>
            ))
            .slice(0, 5)}
        </div>
      );
    }
  }
}

function modifyMarkdownContent(content) {
  // Add custom modifications to the content here

  if (content.includes("```markdown")) {
    const openingBackticksRegex = /```markdown/g;
    const closingBackticksRegex = /```/g;

    // Replace the opening and closing backticks
    let cleanedContent = content.replace(openingBackticksRegex, "");
    cleanedContent = cleanedContent.replace(closingBackticksRegex, "");

    return cleanedContent;
  } else {
    return content;
  }
}

export const MarkdownContent = ({
  content,
  visualization,
  source,
  setFilePreview,
  setShowFilePreview,
}) => {
  const [displayedContent, setDisplayedContent] = useState("");
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  function combineNextCharacters(safeHTMLContent, currentIndex) {
    let data = "";

    for (let index = 0; index < 7; index++) {
      const nextIndex = currentIndex + index;
      if (nextIndex < safeHTMLContent.length) {
        const next_data = safeHTMLContent[nextIndex];
        data += next_data;
      } else {
        break; // Exit the loop if we reach the end of the array
      }
    }
    return data;
  }
  const getFullTagContent = (content, tag, currentIndex) => {
    const endTag = `</${tag}>`;
    const startIndex = content.indexOf(`<${tag}`, currentIndex);
    const endIndex = content.indexOf(endTag, startIndex) + endTag.length;
    return content.substring(startIndex, endIndex);
  };

  useEffect(() => {
    const htmlContent = marked(modifyMarkdownContent(content));
    const safeHTMLContent = DOMPurify.sanitize(htmlContent).replace(
      /<a\s+/g,
      '<a target="_blank" rel="noopener noreferrer" '
    );

    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex < safeHTMLContent.length) {
        if (safeHTMLContent[currentIndex] === "<") {
          const future_letters = combineNextCharacters(
            safeHTMLContent,
            currentIndex
          );

          if (future_letters === "<table>") {
            const tableContent = getFullTagContent(
              safeHTMLContent,
              "table",
              currentIndex
            );
            setDisplayedContent((prev) => prev + tableContent);
            currentIndex += tableContent.length;
            return;
          }
        }

        const nextIndex = Math.min(currentIndex + 1, safeHTMLContent.length); // Display 5 characters at a time
        setDisplayedContent(safeHTMLContent.substring(0, nextIndex));
        currentIndex = nextIndex;
      } else {
        clearInterval(interval);
        setIsTypingComplete(true); // Mark typing effect as complete
      }
    }, 10); // Adjust the speed here as needed

    return () => clearInterval(interval);
  }, [content]);

  return (
    <div className="markdown-container">
      <div
        className="markdown-content cursor-default"
        dangerouslySetInnerHTML={{ __html: displayedContent }}
      />
      {isTypingComplete && visualization ? (
        <div className="w-[100%] h-[300px] flex flex-row gap-10">
          <div className="w-[50%] ">
            <ChartComponent
              labels={visualization.chart_data[0].label}
              data={visualization.chart_data[0].values}
              title={visualization.chart_data[0]?.title}
              chartType="pie"
            />
          </div>
          <div className="w-[50%]">
            <ChartComponent
              labels={visualization.chart_data[1].label}
              data={visualization.chart_data[1].values}
              title={visualization.chart_data[1]?.title}
              chartType="pie"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {isTypingComplete &&
        source &&
        sourceDocumentsData(source, setFilePreview, setShowFilePreview)}
      {isTypingComplete === false && (
        <div className="h-[100px] bg-transparent"></div>
      )}
    </div>
  );
};

export const MarkdownContentQuestion = ({
  content,
  visualization,
  source,
  setFilePreview,
  setShowFilePreview,
}) => {
  // Convert Markdown to HTML
  const htmlContent = marked(modifyMarkdownContent(content));
  // Sanitize the HTML content
  const safeHTMLContent = DOMPurify.sanitize(htmlContent).replace(
    /<a\s+/g,
    '<a target="_blank" rel="noopener noreferrer" '
  );
  return (
    <>
      <div
        className="markdown-content cursor-default"
        dangerouslySetInnerHTML={{ __html: safeHTMLContent }}
      />

      {visualization && (
        <div className="w-[100%] h-[300px] flex flex-row gap-10">
          <div className="w-[50%] ">
            <ChartComponent
              labels={visualization.chart_data[0].label}
              data={visualization.chart_data[0].values}
              title={visualization.chart_data[0]?.title}
              chartType="pie"
            />
          </div>
          <div className="w-[50%]">
            <ChartComponent
              labels={visualization.chart_data[1].label}
              data={visualization.chart_data[1].values}
              title={visualization.chart_data[1]?.title}
              chartType="pie"
            />
          </div>
        </div>
      )}
      {source &&
        sourceDocumentsData(source, setFilePreview, setShowFilePreview)}
    </>
  );
};
