import { sendLogToServer } from "./logging";
import apiEndpoints from "./apiConfig";
import axios from "axios";
import { handleApiError } from "./apiConfig";

export const getFileAPI = async (file_name, page_number) => {
  sendLogToServer("getFileAPI");
  try {
    let response = await axios.get(apiEndpoints.STORAGE.GET_FILE, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: { file_name: file_name, page_number: page_number },
    });
    return response;
  } catch (error) {
    sendLogToServer("Error fetching getFileAPI:", error);
    handleApiError(error);
  }
};

export const getOrgsAPI = async (file_name) => {
  sendLogToServer("getOrgsAPI");
  try {
    let response = await axios.get(apiEndpoints.STORAGE.GET_ORGS);
    return response;
  } catch (error) {
    sendLogToServer("Error fetching getOrgsAPI:", error);
    handleApiError(error);
  }
};
