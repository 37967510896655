import React from "react";

import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/Auth";

export const UnAuthenticatedOnlyRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <>{children}</>;
  } else {
    return <Navigate to="/homepage" />;
  }
};

export const AuthenticatedOnlyRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/" />;
  } else {
    return <>{children}</>;
  }
};
