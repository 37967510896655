import React, { useEffect, useRef } from "react";
import { SVGInjector } from "@tanem/svg-injector";

const SVGIcon = ({ src, fill, stroke, id, className, svg_path_class }) => {
  const svgContainerRef = useRef(null);
  useEffect(() => {
    SVGInjector(document.getElementById(id), {
      afterEach: (error, svg) => {
        if (!error) {
          const paths = svg.getElementsByTagName("path");
          for (const path of paths) {
            path.setAttribute("fill", fill);
            path.setAttribute("stroke", stroke);
            path.setAttribute("class", svg_path_class);
          }
        }
      },
    });
  }, [src, fill, stroke, id, svg_path_class]);

  return (
    <div id={id} className={className} ref={svgContainerRef} data-src={src} />
  );
};

export default SVGIcon;
