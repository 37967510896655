import { sendLogToServer } from "./logging";
import axios from "axios";
import apiEndpoints from "./apiConfig";
import { handleApiError } from "./apiConfig";

export const getModels = async () => {
  sendLogToServer("getModels");
  try {
    let modelData = await axios.get(apiEndpoints.MODEL.GET_ALL_MODELS, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return modelData.data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error getModels: ${error}`);
    handleApiError(error);
  }
};
