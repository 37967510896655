import { sendLogToServer } from "./logging";
import apiEndpoints from "./apiConfig";
import axios from "axios";
import { handleApiError } from "./apiConfig";

export const fetchUserPersonalData = async () => {
  sendLogToServer("Fetching User Personal Data");
  try {
    let userData = await axios.get(apiEndpoints.PROFILE.GET_PROFILE, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return userData.data;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
    handleApiError(error);
  }
};

export const fetchUserThemeData = async (org) => {
  sendLogToServer("Fetching User Theme Data");
  try {
    let userThemeData = await axios.get(apiEndpoints.PROFILE.GET_THEME_DATA, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: { org_name: org },
    });
    return userThemeData.data;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
    handleApiError(error);
  }
};
