import React, { useState, useEffect } from "react";
import SVGIcon from "./SVGIcon";
import dark from "assets/images/dark.svg";
import light from "assets/images/light.svg";

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Retrieve the theme preference from localStorage or use the default (light mode)
    return localStorage.getItem("isDarkMode") === "true";
  });

  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    // Update the theme preference in localStorage
    localStorage.setItem("isDarkMode", newMode.toString());
    // Update the class on the <html> element
    document.documentElement.classList.toggle("dark", newMode);
  };

  useEffect(() => {
    // Set the initial class on the <html> element
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  return (
    // <button
    //   className="p-2 rounded-full bg-gray-300 dark:bg-gray-700"
    //   onClick={toggleTheme}
    // >
    //   {isDarkMode ? "Light Mode" : "Dark Mode"}
    // </button>

    <div className="  flex flex-row justify-between toggle">
      <label htmlFor="dark-toggle" className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            name="dark-mode"
            id="dark-toggle"
            className="checkbox hidden"
            onClick={toggleTheme}
          />
          <div className="block border-2 bg-[#F6F6F6] dark:bg-foreground-dark border-[#8A8A8A] w-14 h-8 rounded-full"></div>
          {isDarkMode ? (
            <>
              <div className="dot absolute left-1 top-1  bg-[#141414] dark:bg-white w-6 h-6 rounded-full transition flex justify-center items-center">
                <SVGIcon
                  id={`darkIcon`}
                  src={dark}
                  fill="white"
                  svg_path_class="dark:fill-black"
                />
              </div>
              <div className="dot absolute right-1 top-1  bg-transparent w-6 h-6 rounded-full transition flex justify-center items-center">
                <SVGIcon
                  id={`lightIcon`}
                  src={light}
                  fill="black"
                  svg_path_class="dark:fill-white"
                />
              </div>
            </>
          ) : (
            <>
              <div className="dot absolute left-1 top-1  bg-transparent w-6 h-6 rounded-full transition flex justify-center items-center">
                <SVGIcon id={`darkIcon`} src={dark} fill="black" />
              </div>
              <div className="dot absolute right-1 top-1  bg-[#141414] w-6 h-6 rounded-full transition flex justify-center items-center">
                <SVGIcon id={`lightIcon`} src={light} fill="white" />
              </div>
            </>
          )}
        </div>
      </label>
    </div>
  );
};

export default ThemeToggle;
