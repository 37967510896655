// import axios from "axios";

export const sendLogToServer = (logData) => {
  console.log(logData);
  //   axios
  //     .post("http://127.0.0.1:5000/logs", logData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       // Log sent successfully
  //     })
  //     .catch((error) => {
  //       // Error sending log
  //     });
};
