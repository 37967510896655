import axios from "axios";
import { sendLogToServer } from "./logging";
import { handleApiError } from "./apiConfig";

const APP_SERVICES_RUNPOD = process.env.REACT_APP_APP_SERVICES_RUNPOD_ENDPOINT;

export const eobRAGPredict = async (data) => {
  sendLogToServer(`eobRAGPredict`);

  try {
    const eob_rag_data = await axios.post(
      `${APP_SERVICES_RUNPOD}/eob-rag-predict`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return { eob_rag_data, error: null };
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error eobRAGPredict: ${error}`);
    handleApiError(error);
  }
};
