import React, { useContext, useState, useEffect } from "react";
import { setUserData } from "../redux/slice/user";
import { useDispatch } from "react-redux";
import { sendLogToServer } from "api/logging";
import { fetchUserPersonalData } from "api/profiles";
import { fetchSession } from "api/auth";
import { useSelector } from "react-redux";
import { fetchUserThemeData } from "api/profiles";
import { setThemeColors } from "helper/GetStartedPageHelper";
import { setUserOrg } from "../redux/slice/user";
// Create a context for authentication
const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  sendLogToServer("USER SESSION CHECK");

  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.userData);

  if (userData.org && userData.org.theme_timer) {
    const timeElapsed = Date.now() - userData.org.theme_timer;
    // const oneHourInMilliseconds = 60000;
    const oneHourInMilliseconds = 3600000;

    if (timeElapsed > oneHourInMilliseconds) {
      fetchUserThemeData().then((userThemeData) => {
        const currentTime = Date.now();
        const updatedThemeData = {
          ...userThemeData,
          theme_timer: currentTime,
        };

        setThemeColors(updatedThemeData);
        dispatch(setUserOrg(updatedThemeData));
      });
      console.log("Theme Timer Expired");
    } else {
      console.log("Theme Timer Not Expired");
    }
  }

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      fetchSession().then((session_response) => {
        if (session_response) {
          const session = session_response.data;
          setSession(session);
          setUser(session?.user);
          if (session?.user) {
            fetchUserPersonalData().then((userData) => {
              dispatch(
                setUserData({
                  id: session?.user.id,
                  full_name: userData.full_name,
                  avatar_url: userData.avatar_url,
                })
              );
            });
          }
          setLoading(false);
        } else {
          setSession(null);
          setUser(null);
          setLoading(false);
        }
      });
    } else {
      setSession(null);
      setUser(null);
      setLoading(false);
    }
  }, [dispatch, access_token]);

  const value = {
    session,
    user,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: Auth.js
//  Author: Everlign Development Team
// ----------------------------
