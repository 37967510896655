import { sendLogToServer } from "./logging";
import axios from "axios";
import apiEndpoints from "./apiConfig";
import { handleApiError } from "./apiConfig";

export const addMessage = async (
  conversation_id,
  content,
  sender,
  model_id,
  visualization,
  source_data,
  final_query
) => {
  sendLogToServer("addMessage");
  try {
    const data = await axios.post(
      apiEndpoints.MESSAGE.ADD_MESSAGE,
      {
        conversation_id: conversation_id,
        content: content,
        sender: sender,
        model_id: model_id,
        visualization: visualization,
        source_data: source_data,
        final_query: final_query ? final_query : "",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    return [data.data];
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error addMessage: ${error}`);
    handleApiError(error);
  }
};

export const getMessages = async (conversation_id) => {
  sendLogToServer("getMessages");
  try {
    const data = await axios.post(
      apiEndpoints.MESSAGE.GET_MESSAGES,
      {
        conversation_id: conversation_id,
        limit: 10,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return data.data.reverse();
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error getMessages: ${error}`);
    handleApiError(error);
  }
};

export const updateMessageReactionLiked = async (
  reaction,
  message_id,
  feedback
) => {
  sendLogToServer("updateMessageReactionLiked");

  const reaction_ob = {
    message_id: message_id,
    reaction_liked: reaction,
    feedback: feedback,
  };

  if (feedback === null) {
    delete reaction_ob.feedback;
  }

  try {
    const data = await axios.post(
      apiEndpoints.MESSAGE.UPDATE_MESSAGE_LIKE,
      reaction_ob,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return [data.data];
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error updateMessageReactionLiked: ${error}`);
    handleApiError(error);
  }
};
